import React from "react";
import TextPageLayout from "../components/layouts/TextPageLayout";
import AccordionGroup from "../components/base/AccordionGroup";
import Testimonials from "../components/Testimonials";
import CardGroup from "../components/base/CardGroup";
import aboutContent from "../data/about/about";
import volunteersContent from "../data/about/volunteers";
import SEO from "../components/SEO";
import testimonials from "../data/about/testimonials";
import { Link } from "gatsby";

function About() {
  return (
    <TextPageLayout title="About Cali Skills">
      <SEO path="/about/" title="About Cali Skills" />
      <p>
        Cali Skills gives you everything you need to improve your bodyweight
        fitness in a fun, engaging, and accessible way. Our Web App lets you
        track your progress, and perform bespoke workouts, all while growing
        stronger and healthier.
      </p>
      <p>
        The <Link to="/release-notes/">releases page</Link> is regularly updated
        with details about all the latest Cali Skills features.
      </p>
      <AccordionGroup accordionData={aboutContent} />
      <br />
      <h2>Testimonials</h2>
      <Testimonials testimonials={testimonials} />
      <br />
      <h2>About the volunteers</h2>
      <CardGroup cardsData={volunteersContent} />
    </TextPageLayout>
  );
}

export default About;
