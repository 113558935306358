export default [
  {
    id: 1,
    name: "Derrick G. Wood from Arizona, USA",
    quote:
      "I think this is going to be really useful. I am 40 and just started bodyweight workouts about a month ago. Thank you for this."
  },
  {
    id: 2,
    name: "Harald from Trondheim, Norway",
    quote:
      "This is super helpful, I also love the workout generator, I will be using this from now on. Got me up from the sofa from a 2 month break so guess it have done wonders already"
  },
  {
    id: 3,
    name: "Riyad from Salé, Morocco",
    quote:
      "I am adding your site to my Cool Websites bookmark folder, keep up the great work."
  },
  {
    id: 4,
    name: "SA from Durham, England",
    quote:
      "I'm literally excited about going home and trying this out tonight, after a long hard day at work. Thanks so much! May Brodin bless you with much swolery."
  }
];
