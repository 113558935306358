import React from "react";
import "../../styles/Card.css";
import { OutboundLink } from "react-ga";

export interface CardData {
  image: React.ReactElement;
  description: string;
  title: string;
  to: string;
}

interface Props {
  cardsData: CardData[];
}

function Card({ cardsData }: Props) {
  return (
    <div className="CardGroup">
      {cardsData.map(card => {
        return (
          <div key={card.title} data-testid="Card" className="Card">
            <OutboundLink
              eventLabel={`to instagram ${card.title}`}
              to={card.to}
              target="_blank"
              className="Card__image"
              rel="noopener"
            >
              {card.image}
              <div className="Card__text">
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            </OutboundLink>
          </div>
        );
      })}
    </div>
  );
}

export default Card;
