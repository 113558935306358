import React from "react";
import SuhagProfile from "../../components/images/SuhagProfileImage";
import AndricoProfileImage from "../../components/images/AndricoProfileImage";

const volunteersData = [
  {
    title: "Andrico",
    description:
      "Software Engineer and calisthenics enthusiast based in London, England. I'm fascinated with video game skill trees, which crossed with my love of calisthenics. Not long after, Cali Skills was born.",
    to: "https://www.instagram.com/andricokaroulla/?hl=en",
    image: (
      <AndricoProfileImage alt="Create Andrico feeling forever small in an oversized beach chair in Brighton." />
    )
  },
  {
    title: "Suhag",
    description:
      "Software Engineer from Bangalore, India. Been practicing calisthenics for 2 years now. Loved the idea of tracking your progress using skill trees when I discovered the app on reddit and volunteered to contribute right away.",
    to: "https://www.instagram.com/suhagnolastname/",
    image: (
      <SuhagProfile alt="Volunteer Suhag performing a handstand on the beach." />
    )
  }
];

export default volunteersData;
