import React from "react";
import { OutboundLink } from "react-ga";

const aboutData = [
  {
    key: 3,
    title: "How to save Cali Skills to your homescreen/desktop",
    content: (
      <div>
        <p>
          Desktop: Browsers like Chrome allow you to save web applications to
          your desktop in an number of ways; by clicking the installation bar
          that appears when on entering the site, and by clicking the '+' in the
          address bar.
        </p>
        <p>
          Mobile: Similar rules apply to those using Cali Skills on
          Android devices. Unfortunately, on iOS the process is a little more
          involved. Cali Skills can't be installed on Chrome, only on
          Safari. Access the share menu and save the app to your homescreen.{" "}
          <OutboundLink
            to="https://calisthenics-skills-assets.s3.us-east-1.amazonaws.com/iphone-add-to-home-screen.mp4"
            target="_blank"
            eventLabel="Accessing A2HS video"
          >
            Here's a video showing this process
          </OutboundLink>
          .
        </p>
        <p>
          Cali Skills was chosen to be a web application and not
          installable via the App Store or Play Store, so it can remain
          accessible to everyone, regardless of device. For more comprehensive
          instructions, you can follow the steps laid out in{" "}
          <OutboundLink
            to="https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/"
            target="_blank"
            eventLabel="HowToGeek: PWA installation"
          >
            this article
          </OutboundLink>
          .
        </p>
      </div>
    )
  }
];

export default aboutData;
