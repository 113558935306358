import React from "react";
import "../styles/Testimonials.css";

interface Testimonial {
  id: number;
  name: string;
  quote: string;
}

interface Props {
  testimonials: Testimonial[];
}

function Testimonials(props: Props) {
  return (
    <div>
      {props.testimonials.map(testimonial => {
        return (
          <div className="Testimonial" key={testimonial.id}>
            <p>"{testimonial.quote}"</p>
            <p className="Testimonial__name">- {testimonial.name}</p>
          </div>
        );
      })}
    </div>
  );
}

export default Testimonials;
